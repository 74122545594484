/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 路由配置
 * @FilePath: \tp-vue2\src\router\index.js
 */

import Vue from "vue";
import VueRouter from "vue-router";
// import PageRoutes from './page'
// import ViewsRoutes from './views'

Vue.use(VueRouter);
// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const Default = () => import("@/page/index/index.vue");
const Home = () => import("@/views/business-a/page-a/index.vue");
const aboutUs = () => import("@/views/aboutNew/aboutNew.vue");
const news = () => import("@/views/news/news.vue");
const custer = () => import("@/views/custer/index.vue");
const newsDetail = () => import("@/views/news/newsDetail.vue");
const product = () => import("@/views/product/index.vue");
const partner = ()=>import("@/views/partner/partner.vue")
// 所有路由的集合
const routes = [
  /*...PageRoutes,
    ...LayoutViewsRoutes*/
  {
    path: "/",
    name: "Default",
    component: Default,
    redirect: "/",
    children: [
      { path: "/", name: "BusinessAPageA", component: Home },
      { path: "product", name: "product", component: product },
      { path: "partner", name: "partner", component: partner },
      { path: "about/aboutus", name: "aboutUs", component: aboutUs },
      { path: "news", name: "news", component: news },
      { path: "custer", name: "custer", component: custer },
      {
        path: "news/detail/:id",
        name: "newsDetail",
        component: newsDetail,
      },
    ],
  },
];
const router = new VueRouter({
  // base: process.env.BASE_URL,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
