/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 用户相关状态管理
 * @FilePath: \tp-vue2\src\store\modules\user.js
 */

import { getStorage, setStorage } from "@/utils/storage";
import { encryption } from "@/utils/util";
import { login, logout } from "@/api/login";
const user = {
  state: {
    userInfo: getStorage("userInfo") || {},
    access_token: getStorage("access_token") || "",
    refresh_token: getStorage("refresh_token") || "",
    dialogVisible: false,
    firstFlag: true,
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token;
      setStorage("access_token", access_token);
    },
    SET_REFRESH_TOKEN: (state, refresh_token) => {
      state.refresh_token = refresh_token;
      setStorage("refresh_token", refresh_token);
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
      setStorage("userInfo", userInfo);
    },
    DIALOG_SHOW: (state) => {
      state.dialogVisible = true;
    },
    DIALOG_HIDDEN: (state) => {
      state.dialogVisible = false;
    },
    FIRST_TRUE: (state) => {
      state.firstFlag = true;
    },
    FIRST_FALSE: (state) => {
      state.firstFlag = false;
    },
  },
  actions: {
    // 登入
    login({ commit }, params) {
      return new Promise((resolve) => {
        // 特殊处理：密码加密传输
        const userForm = encryption({
          data: params,
          key: "pigxpigxpigxpigx",
          param: ["password"],
        });
        login(userForm)
          .then((response) => {
            const data = response.data;
            // 用户信息提交到状态管理中
            commit("SET_ACCESS_TOKEN", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_USER_INFO", data.user_info);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
        resolve();
      });
    },

    // 登出
    logout({ commit }) {
      return new Promise((resolve) => {
        logout()
          .then(() => {
            // 重置用户状态
            commit("SET_USER_INFO", {});
            commit("SET_ACCESS_TOKEN", "");
            commit("SET_REFRESH_TOKEN", "");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
        resolve();
      });
    },
    dialogShow({ commit }) {
      commit("DIALOG_SHOW");
    },
    dialogHidden({ commit }) {
      commit("DIALOG_HIDDEN");
    },
    firstTrue({ commit }) {
      commit("FIRST_TRUE");
    },
    firstFalse({ commit }) {
      commit("FIRST_FALSE");
    },
  },
};
export default user;
