/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 状态管理入口，集合不同模块，及计算属性
 */
import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import common from "./modules/common";
import getters from "./getters";

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    user,
    common,
  },
  getters,
});

export default store;
