<template>
  <div>
    <div class="fixed_box">
      <el-popover
        placement="left"
        trigger="hover"
        v-for="(item, index) in fixedList"
        :key="item.value"
        :disabled="item.value == 'tra'"
      >
        <div class="tel">
          <div class="telNum">{{ item.value }}</div>
        </div>
        <!-- <div v-show="item.value == 'num'" class="num">
          <img :src="numUrl" alt="" />
        </div>
        <div v-show="item.value == 'mini'" class="mini">
          <img :src="miniUrl" alt="" />
        </div>
        <div v-show="item.value == 'app'" class="app">
          <img :src="appUrl" alt="" />
        </div> -->
        <div slot="reference" class="fixed_row" @click="toWeb(item.value)">
          <img :src="item.imgUrl" alt="" />
          <div v-html="item.name"></div>
        </div>
      </el-popover>
    </div>
    <div class="fixed_top" @click="scrollToTop" v-show="bool">
      <img :src="topUrl" alt="" />
    </div>
    <div class="flxed_link" @click="showDialog"></div>
  </div>
</template>

<script>
const cubic = (value) => Math.pow(value, 3);
const easeInOutCubic = (value) =>
  value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;
export default {
  name: "BoradSide",

  data() {
    return {
      // firstFlag: true,
      bool: false,
      numUrl: require("@/assets/homepage/num.png"),
      miniUrl: require("@/assets/homepage/mini.png"),
      appUrl: require("@/assets/homepage/app.png"),
      topUrl: require("@/assets/homepage/top_icon.png"),
      fixedList: [
        {
          name: "电话",
          value: "400-780-0159(服务热线)",
          imgUrl: require("@/assets/homepage/phone.png"),
        },
        // {
        //   name: "官网",
        //   value: "www.skygovern.com",
        //   imgUrl: require("@/assets/homepage/website.png"),
        // },
        {
          name: "邮箱",
          value: "tianhua@skygovern.com",
          imgUrl: require("@/assets/homepage/email.png"),
        },
        {
          name: "地址",
          value: "金华市金东区多湖街道光南路广润翰城北门4幢120室",
          imgUrl: require("@/assets/homepage/address.png"),
        },
        // {
        //   name: "哈尔滨<br>运输服务",
        //   value: "tra",
        //   imgUrl: require("@/assets/homepage/fixed5.png"),
        // },
      ],
    };
  },

  methods: {
    toWeb(value) {
      if (value == "tra") {
        window.open("http://111.42.74.45:701/");
      }
    },
    returnTop() {
      var ksHeight = document.documentElement.offsetHeight;
      var scrollT =
        document.documentElement.scrollTop || document.body.scrollTop;
      var scrollH = document.body.scrollHeight;
      if (ksHeight + scrollT >= scrollH + 100) {
        window.scrollBy(0, -ksHeight);
      }
    },
    showDialog() {
      this.$store.dispatch("dialogShow");
    },
    handleScroll() {
      var ksHeight = document.documentElement.offsetHeight;
      var scrollT =
        document.documentElement.scrollTop || document.body.scrollTop;
      var scrollH = document.body.scrollHeight;
      if (ksHeight + scrollT > scrollH) {
        this.bool = true;
      } else {
        this.bool = false;
      }
      if (scrollT > 1000 && this.firstFlag) {
          // this.showDialog();
        this.$store.dispatch("firstFalse");
      }
      return this.bool;
    },
    scrollToTop() {
      const el = document.documentElement;
      const beginTime = Date.now();
      const beginValue = el.scrollTop;

      const rAF =
        window.requestAnimationFrame || ((func) => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = 0;
        }
      };
      rAF(frameFunc);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    firstFlag: {
      get() {
        return this.$store.state.user.firstFlag;
      },
      set(val) {},
    },
  },
};
</script>
<style scoped lang="scss">
.fixed_box {
  position: fixed;
  top: 300px;
  right: 15px;
  width: 66px;
  height: 280px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 15px 0px rgba(1, 77, 214, 0.13);
  border-radius: 12px 12px 12px 12px;
  opacity: 0.8;
  box-sizing: border-box;
  padding: 13px 0 13px 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  .fixed_row {
    cursor: pointer;
  }
  img {
    width: 40px;
    height: 40px;
    // margin-bottom: 8px;
  }
}
.fixed_top {
  position: fixed;
  top: 600px;
  right: 15px;
  z-index: 1000;
  width: 66px;
  height: 66px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(1, 77, 214, 0.13);
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
}
.tel {
  height: 50px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .telNum {
    font-size: 20px;
    font-weight: 500;
    color: #295bdd;
    line-height: 23px;
  }
}
.flxed_link {
  width: 96px;
  height: 198px;
  background: url("~@/assets/homepage/link.png") no-repeat center center;
  background-size: 100% 100%;
  opacity: 1;
  position: fixed;
  top: 700px;
  right: 0px;
  z-index: 1000;
  cursor: pointer;
}
</style>
