/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: In User Settings Edit
 * @FilePath: \tp-vue2\src\main.js
 */
import "babel-polyfill";
import "classlist-polyfill";
import Vue from "vue";
import axios from "./http/axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store"; // 全局状态管理
import * as filters from "./filters"; // 全局filter
import "@/directives"; // 全局指令
import "@/components"; // 全局组件
import "./flexible";
import ElementUI from "element-ui"; // element组件库
import "element-ui/lib/theme-chalk/index.css"; // element组件库样式

//3D轮播图
import Carousel3d from 'vue-carousel-3d';
import "./styles/index.scss";
import fa from "element-ui/src/locale/lang/fa"; // 全局样式文件
// import "@/mock"; // mockjs拦截请求
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;
// 注入使用组件库
Vue.use(ElementUI);
// 注入使用axios
Vue.use(VueAxios, axios);
//3D轮播图
Vue.use(Carousel3d);
Vue.use(vueSwiper);

// 加载过滤器
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
