import request from "@/utils/request";
import qs from "qs";

export function getNews(params) {
  let dataObj = qs.stringify(params);
  return request({
    url: "/news/getNewsList",
    method: "post",
    data: dataObj,
  });
}
export function getNewsDetail(params) {
  let dataObj = qs.stringify(params);
  return request({
    url: "/news/getNewsById?",
    method: "post",
    data: dataObj,
  });
}
export function submitLink(params) {
  let dataObj = qs.stringify(params);
  return request({
    url: "/admin/contact/addUserContactData",
    method: "post",
    data: dataObj,
  });
}
