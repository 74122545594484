<template>
  <div>
    <el-dialog
        :visible.sync="dialogVisible"
        width="48%"
        top="25vh"
        @close="hideDialog"
    >
      <div class="link_title">联系我们</div>
      <!--      <div class="sub_title">联系我们联系我们联系我们联系我们联系我们</div>-->
      <el-form
          :model="linkForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="linkForm"
          :hide-required-asterisk="true"
          label-position="left"
      >
        <el-form-item label="姓名：" prop="userName" class="flex">
          <span slot="label" class="form_label">
            <i class="el-icon-user"></i>
            姓名：
          </span>

          <el-input v-model="linkForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="电话：" prop="phone" class="flex">
          <span slot="label" class="form_label">
            <i class="el-icon-phone-outline"></i>
            电话：
          </span>
          <el-input v-model="linkForm.phone"></el-input>
        </el-form-item>

        <el-button class="submit-btn" type="primary" @click="submitForm()">提交</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {submitLink} from "@/api/home";
import {MessageBox, Message} from "element-ui";

export default {
  name: "LinkDialog",

  data() {
    return {
      timer: null,
      linkForm: {userName: "", phone: ""},
      rules: {
        userName: [{required: true, message: "请输入姓名", trigger: "blur"}],
        phone: [{required: true, message: "请输入电话", trigger: "blur"}],
      },
    };
  },

  methods: {
    hideDialog() {
      this.linkForm.userName = ""
      this.linkForm.phone = ""
      this.$store.dispatch("dialogHidden");
    },
    submitForm() {
      submitLink(this.linkForm).then((res) => {
        if (res.code === 0) {
          this.hideDialog()
          this.$message.success('保存成功')
        }
      });
    },
    // setShowDialog() {
    //   this.timer = setTimeout(() => {
    //     this.$store.dispatch("dialogShow");
    //   }, 1000 * 60);
    // },
  },
  watch: {
    // dialogVisible(newVal, oldVal) {
    //   if (newVal) {
    //     if (this.timer) {
    //       clearInterval(this.timer);
    //     }
    //   } else {
    //     this.setShowDialog();
    //   }
    // },
  },
  mounted() {
    // this.setShowDialog();
  },
  computed: {
    dialogVisible: {
      get() {
        return this.$store.state.user.dialogVisible;
      },
      set(val) {
      },
    },
  },
};
</script>
<style scoped lang="scss">
.dialog_title {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  line-height: 38px;
  margin-bottom: 35px;
}

.dialog_desc {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: #295bdd;
}

.dialog_ma {
  margin-bottom: 20px;
}

.submit-btn {
  width: 170px;
  font-size: 24px;
}

::v-deep {
  .el-input__inner {
    font-size: 24px;
    min-width: 230px;
  }

  .el-form-item__content {
    margin-left: 0px !important;
  }
}

::v-deep .el-dialog {
  border-radius: 24px 24px 24px 24px;
}

::v-deep .dialog_body {
  border-radius: 24px !important;
}

::v-deep .el-dialog__body {
  height: 500px;
  background: url("https://imagepub.chinau8.cn/link_me.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 60px;
  border-radius: 0px 0px 24px 24px;
}

.el-dialog__header {
  // display: none;
}

.link_title {
  font-size: 40px;
  font-weight: bold;
  color: #333333;
  line-height: 47px;
  margin-top: 40px;
  margin-bottom: 70px;
}

.sub_title {
  font-size: 26px;
  font-weight: 500;
  color: #666666;
  line-height: 30px;
  margin: 20px 0 40px 0;
}

.form_label {
  min-width: 25%;
  font-size: 24px;
  font-weight: 500;
  color: #333333;
}

.linkForm {
  width: 500px;
  margin: 0 auto;
}
</style>
